import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig();
const apiDomain = publicRuntimeConfig.apiDomain;

export function loadForter() {
  setTimeout(function () {
    // 延迟加载Forter脚本
    const FORTER_SCRIPT_SRC =
      apiDomain === "https://www.crazysales.com.au"
        ? "https://static.crazysales.com.au/static-next/forter-production-snippet.js"
        : "https://static.crazysales.com.au/static-next/forter-sandbox-snippet.js";

    let script = document.createElement('script')
    script.id = 'forter'
    script.async = true
    script.src = FORTER_SCRIPT_SRC

    document.head.appendChild(script)
  }, 1); // 2000毫秒（即2秒）后加载脚本和样式
}