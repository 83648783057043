import styles from '@/styles/searchBar.module.scss'
import { useState, useEffect } from "react";
import { useDebounce } from 'react-use'
import React from 'react';
import { useClickOutside, useCallbackState } from '@/utils/useRef'
import { useRef } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import {
  CloseOutlined
} from '@ant-design/icons'

import systemApi from '@/service/modules/systemApi'
import { useRouter } from 'next/router';

import {
  ClockCircleOutlined,
  RiseOutlined,
  SearchOutlined
} from '@ant-design/icons';


export default function SearchBar() {
  const history = useRouter()

  interface ProductItem {
    productId: number | string;
    name: string;
    price: number | null;
    normalPrice: number | null;
    filename: string;
    url: string;
  }

  const [showSuggest, setShowSuggest] = useState(false);
  const [showCategoriesList, setShowCategoriesList] = useState(false);
  const [categories, setcategories] = useState('All Categories');
  const [showType, setShowType] = useState('');
  const [inputValue, setInputValue] = useCallbackState('');


  const [recentSearchList, setRecentSearchList]: any[] = useState([])
  const [recentProductsList, setRecentProductsList] = useState<ProductItem[]>([]);
  const [trendingSearchList, setTrendingSearchList] = useState([])
  const [trendingProductList, setTrendingProductList] = useState<ProductItem[]>([]);


  const [productsList, setProductsList] = useState<ProductItem[]>([]);
  const [suggestionsList, setSuggestionsList]: any[] = useState([])
  const [noSuggest, setNoSuggest] = useState(false)


  useEffect(() => {
    getHotSearch()
  }, [history.pathname])

  const getHotSearch = async () => {
    try {
      let res = await systemApi.getTrendingSearch()
      if (res.data) {
        const { trendingWords, trendingProducts } = res.data
        setTrendingSearchList(trendingWords)
        setTrendingProductList(trendingProducts)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const ref = useRef(null)
  useClickOutside(ref, () => {
    if (showCategoriesList) {
      setShowCategoriesList(false)
    }
  })

  const searchRef = useRef(null)
  useClickOutside(searchRef, () => {
    // if (showSuggest) {
    //   setShowSuggest(false)
    // }
  })

  // 获取推荐数据
  const getSuggestProduct = async () => {
    try {
      // 先把商品置空
      setProductsList([])

      const res = await systemApi.getSearchSuggest({ keyword: inputValue })

      if (res.data) {
        setProductsList(res.data.suggestProducts)
        setSuggestionsList(res.data.suggestKeywords || [`${inputValue}`])
        setNoSuggest(false)

        if (!res.data.suggestKeywords || res.data.suggestKeywords.length <= 0) {
          console.log('sugesst empty');
          setSuggestionsList([`${inputValue}`])
          setProductsList(res.data.trendingProducts)
          setNoSuggest(true)
        }
        if (!res.data.suggestProducts || res.data.suggestProducts.length <= 0) {
          console.log('suggestProducts empty');
          setProductsList(res.data.trendingProducts)
          setNoSuggest(true)
        } else {
          setProductsList(res.data.suggestProducts)
          setNoSuggest(false)
        }
      } else {
        setProductsList([])
        setSuggestionsList([`${inputValue}`])
        setNoSuggest(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useDebounce(
    () => {
      if (inputValue && inputValue.length >= 3) {
        getSuggestProduct()
        setShowType('suggestions')
      } else {
        setShowType('hot')
        setProductsList([])
      }
    },
    500,
    [inputValue]
  );

  // 搜索框按下Enter事件
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onSearch(e.target.value)
    }
  };

  // 搜索逻辑
  function onSearch(value: string) {
    let url: string = ''
    if (!value) {
      url = "/hottest-deals"
    } else {
      let keyword = value.replace(/ /g, "+")
      if (categories == 'All Categories') {
        url = `/search/${keyword.replace(/\s/g, '+')}`
      } else {
        url = `/search/${keyword.replace(/\s/g, '+')}/category/${categories}/`
      }
    }
    window.location.href = url
  }

  // 删除指定RecentItem
  const handleRemoveRecentItem = (index: number) => {
    let finnalList: any[] = []
    let currentRecentSearchList = JSON.parse(JSON.stringify(recentSearchList))
    currentRecentSearchList.forEach((item: any, i: number) => {
      if (index != i) {
        finnalList.push(item)
      }
    })

    setRecentSearchList(JSON.parse(JSON.stringify(finnalList)) || [])
    localStorage.setItem('recentSearchList', JSON.stringify(finnalList));
  }

  // 删除全部RecentItem
  const handleRemoveAllRecent = () => {
    setRecentSearchList([])
    localStorage.removeItem('recentSearchList')
  }

  const router = useRouter();
  useEffect(() => {
    // 获取search页面的参数，赋值给输入框
    if (router.asPath.includes('/search/')) {
      const { slug } = router.query
      if (!slug || slug.length <= 0) return

      const path = slug[0]
      const regex = /([^?]+)/;

      // 使用正则表达式匹配并提取路径部分
      var match = path.match(regex)
      // 获取匹配到的结果
      let categoryPath = match ? match[1] : slug[0];
      
      setInputValue(String(categoryPath).replace(/\+/g, " "))
    }

    // 赋值 recentSearchList、recentProductsList
    if (localStorage.getItem('recentSearchList') && localStorage.getItem('recentSearchList') != null) {
      setRecentSearchList(JSON.parse(String(localStorage.getItem('recentSearchList'))) || [])
    }
    if (localStorage.getItem('recentProductsList') && localStorage.getItem('recentProductsList') != null) {
      setRecentProductsList(JSON.parse(String(localStorage.getItem('recentProductsList'))) || [])
    }
  }, [])

  // 渲染商品推荐列表
  const ProductList = () => {
    let listToShow: ProductItem[]

    if (showType === 'hot') {
      listToShow = trendingProductList

      if (recentProductsList && recentProductsList.length > 0) {
        listToShow = recentProductsList
      }
    } else {
      listToShow = productsList;
    }

    return (
      <div className={styles['product-list']}>
        {
          listToShow.map((item: ProductItem, index: number) => (
            <Link
              href={item.url}
              legacyBehavior
              key={index}
            >
              <a
                className={styles['product-item']}
                rel="noopener noreferrer"
              >
                <div className={styles['product-item-content']} key={item.filename}>
                  <Image
                    className={styles['product-img']}
                    src={item.filename}
                    alt={item.name}
                    width={136}
                    height={136}></Image>
                    
                  <div className={styles['item-info']}>
                    <div className={styles['product-name']}>
                      {item.name}
                    </div>
                      <div className={styles['normal-price']}>
                        {
                          !!item.normalPrice &&
                          <>${item.normalPrice}</>
                        }
                      </div>
                    <div className={styles['price']}>
                      ${item.price}
                    </div>
                  </div>
                </div>
              </a>
            </Link>
          ))
        }
      </div>
    )
  }

  const SuggestionTitle = () => {
    let title = ''
    if (showSuggest) {
      if (showType === 'hot') {
        title = 'Trending Products'
  
        if (recentProductsList && recentProductsList.length > 0) {
          title = 'Recent Viewed'
        }
      } else {
        if (noSuggest) {
          title = `No results for ${inputValue}, checkout these other products`
        } else {
          title = 'Products Suggested'
        }
      }
    }

    return <div className={styles['title']}>{title}</div>;
  };

  // 查看全部搜索结果
  const handleViewAllResult = () => {
    let keyword = inputValue

    setShowSuggest(false)
    router.push(encodeURI(`/search/${keyword}/`))
  }

  const ViewAllProduct = () => {
    if (showSuggest) {
      if (showType === 'hot') {
        console.log('hot')
      } else if (showType === 'suggestions' && productsList.length >= 4) {
        return <div className={styles['view-all']} onClick={handleViewAllResult}>View all result</div>;
      }
    }

    return <div></div>;
  };

  return (
    <div className={styles['search-bar']} ref={searchRef}>
      <div className={styles['search-bar-box']}>
        {/* 搜索输入框 */}
        <div className={styles['search-input-box']}>
          <input
            id="key"
            type="text"
            value={inputValue}
            placeholder="shop 10000+ products online"
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={() => { setShowSuggest(true) }}
            onKeyDown={(e) => handleKeyDown(e)}
            className={styles['search-input']}
            autoComplete="off"
          />
        </div>

        {/* 搜索按钮 */}
        <div id="search_btn" className={styles['search-btn-box']} onClick={() => { onSearch(inputValue) }}>
          <i className={`iconfont csicon-search ${styles['search-icon']}`}></i>
        </div>

        {
          showSuggest &&
          <div className={styles['search-result-content']}>
            <CloseOutlined className={styles['close-icon']} onClick={() => { setShowSuggest(false) }} />

            <div className={styles['recommend-box']}>
              {
                showType == 'hot'
                ?
                <>
                  {/* 搜索记录和trending searches */}

                  {
                    !!(recentSearchList && recentSearchList.length > 0) &&
                    <div className={styles['search-recommend']}>
                      <div className={styles['title']} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        Recent Searches
                        <div className={styles['clear-all']} onClick={handleRemoveAllRecent}>Clear all</div>
                      </div>

                      <div className={styles['recommend-list']}>
                        {
                          recentSearchList.map((item: any, i: number) =>
                            <div className={styles['recommend-item-box']} key={`${item}${i}`}>
                              <div className={styles['recommend-item']}>
                                <ClockCircleOutlined style={{ flexShrink: 0 }}></ClockCircleOutlined>

                                <Link
                                  href={encodeURI(`/search/${item.replace(/\s/g, '+')}/`)}
                                  legacyBehavior
                                  key={i}
                                >
                                  <a rel="noopener noreferrer" title={item} style={{ width: 'calc(100% - 40px)' }}>
                                    <div className={styles['recommend-text']}>
                                      {item}
                                    </div>
                                  </a>
                                </Link>

                                <CloseOutlined 
                                  className={styles['clear-item']}
                                  onClick={() => { handleRemoveRecentItem(i) }} 
                                />
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  }

                  {
                    !!(trendingSearchList && trendingSearchList.length > 0) &&
                    <div className={styles['trending-searches']}>
                      <div className={styles['title']}>Trending Searches</div>

                      <div className={styles['trending-list']}>
                        {
                          trendingSearchList.map((item: any, i: number) =>
                            <Link
                              href={encodeURI(`/search/${item.replace(/\s/g, '+')}/`)}
                              legacyBehavior
                              key={i}
                            >
                              <a
                                className={`${styles['suggestions_content_item']}`}
                                rel="noopener noreferrer"
                                title={item}
                              >
                                <div className={styles['trending-item']}>
                                  <RiseOutlined></RiseOutlined>

                                  <div className={styles['trending-text']}>
                                    {item}
                                  </div>
                                </div>
                              </a>
                            </Link>
                          )
                        }
                      </div>
                    </div>
                  }
                </>
                :
                // 搜索建议
                <div className={styles['suggested-searches']}>
                  <div className={styles['title']}>Suggested Searches</div>
                  <div className={styles['suggested-list']}>
                    {
                      suggestionsList.map((item: any, i: number) =>
                        <Link
                          href={encodeURI(`/search/${item.replace(/\s/g, '+')}/`)}
                          legacyBehavior
                          key={i}
                        >
                          <a
                            className={`${styles['suggestions_content_item']}`}
                            rel="noopener noreferrer"
                          >
                            <div className={styles['suggested-item']}>
                              <SearchOutlined></SearchOutlined>

                              <div className={styles['suggested-text']}>
                                {item}
                              </div>
                            </div>
                          </a>
                        </Link>
                      )
                    }
                  </div>
                </div>
              }
            </div>

            <div className={styles['product-box']}>
              <SuggestionTitle></SuggestionTitle>

              <ProductList></ProductList>

              <ViewAllProduct></ViewAllProduct>
            </div>
          </div>
        }

      </div>

      {/* 增加一个蒙层 */}
      {
        showSuggest &&
        <div className={styles['search-mask']} onClick={() => { setShowSuggest(false) }}></div>
      }
    </div >
  )
}
