import styles from '@/styles/recommend.module.scss'
import { useState, useEffect, useCallback } from 'react'
import { useCallbackState } from '@/utils/useRef'
import GoodsComponent from '@/components/GoodsComponent'
import { Button } from 'antd'

interface ProductItem {
  type: string,
  position?: number,
  location?: string;

  productID: number;
  name: string;
  url: string;
  imgUrl?: string;

  thumbnail?: string;
  smallThumbnail?: string;
  newThumbnail?: string;
  filename?: string;

  price: number;
  normalPrice: number;

  isGroup: number;
  inStock: number;
  preSales: number;

  freeShipping: number;
  onSale: number;
  reduced: number;
  isNew: number;
  isEmailOnly: any,
  promotionTypeID: number;

  promotion: {
    productID: number;
    promotionType: number;
    promotionPrice: number;
    normalPrice: number;
    endDate: string;
    pointRate: string;
    promotionRate: number;
  };
  review: {
    avgRank: number;
    total: number;
  };

  tagTitle?: string;
  tagBgColor?: string;
  tagFontColor?: string;

  [key: string]: any;
}

interface Props {
  recommendData?: ProductItem[]
  viewMore: any
  loading: boolean
}

export default function Recommend({ recommendData, viewMore, loading }: Props) {
  const [dataLength, setDataLength] = useState(18)
  const [recommendDataLength, setRecommendDataLength] = useCallbackState(0)

  /**
   * 监听页面resize事件, 根据屏幕大小改变轮播图展示个数
   * e 用于获取最新recommendData长度，为number类型才可用
   */
  const handleResize = useCallback((e: any) => {
    let lineCount = 0
    let defaultLength = 0
    let quata = 3
    
    if (window.innerWidth > 1340) {
      lineCount = 6
      defaultLength = 18
    } else if (window.innerWidth >= 1150) {
      lineCount = 5
      defaultLength = 15
    } else if (window.innerWidth > 850) {
      lineCount = 4
      defaultLength = 16
    } else {
      lineCount = 3
      defaultLength = 18
    }

    quata = (Math.floor((typeof(e) == 'number' ?  e : recommendDataLength) / lineCount))
    setDataLength(quata * lineCount || defaultLength)
  }, [recommendDataLength])

  useEffect(() => {
    setRecommendDataLength(JSON.parse(JSON.stringify(recommendData))?.length, handleResize)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendData])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <>
      {
        recommendData && recommendData.length
        ?
        <div className={styles['recommend']}>
          <div className={styles['title']}>
            <h2>Recommend for you</h2>
          </div>
          <div className={styles['content']}>
            <div className={styles['recommend-list']}>
              {
                dataLength > 0 &&
                recommendData?.slice(0, dataLength)?.map((item: ProductItem, index:number) => (
                  <div key={item.productID} className={styles['recommend-item']}>
                    <GoodsComponent 
                      {...item}
                      position={index}
                      location="homepage_recommend"
                      type='Recommend'
                    />
                  </div>
                ))
              }
            </div>
            <div className={styles['view-more']}>
              <Button 
                className={styles['load-btn']}
                loading={loading}
                onClick={viewMore}>View More &gt;</Button>
            </div>
          </div>
        </div>
        :
        null
      }
    </>
  )
}