import '@/styles/globals.scss'
import '@/styles/reset.scss'
import '@/public//fonts/csiconfont.css'
import "@/styles/swiperSelf.scss"
import type { AppProps } from 'next/app'
import withTheme from '@/theme';
import { message } from 'antd';
import { Provider } from 'react-redux';
import store from '@/store/store';

// 在 _app.js 文件中进行全局配置
message.config({
  top: 300, // 消息框距离顶部的距离
  duration: 3, // 消息框自动关闭的时间（单位：秒）
  maxCount: 3, // 最大显示数量，超过数量时会自动关闭最早的消息框
});

export default function App({ Component, pageProps }: AppProps) {
  // @ts-ignore
  return (
    <Provider store={store}>
      {withTheme(<Component {...pageProps} />)}
    </Provider>
  )
}
