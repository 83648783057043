export function loadJekka() {
  setTimeout(function () {
    // 创建并引入外部JS
    const jekkaJs = document.createElement('script');
    jekkaJs.defer = true;
    if (window.location.hostname == 'www.crazysales.com.au') {
      jekkaJs.src = 'https://api.jekka.ai/api/v1/third/config/cs?id=8tjxyPyecqMd4053jZvZ+uhiywnEUO59Wf0HPlsh1so=';
    } else {
      jekkaJs.src = 'https://api.jekka.ai/api/v1/third/config/cs?id=zu4ih2u65OFIolBMgUKEqbEfgjKHinQ/QT6cYzl9v6Y=';
      // jekkaJs.src = 'https://api.jekka.ai/api/v1/third/config/cs?id=8tjxyPyecqMd4053jZvZ+uhiywnEUO59Wf0HPlsh1so=';
    }
    document.body.appendChild(jekkaJs);
  }, 1);
}