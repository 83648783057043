import { useEffect, useState } from 'react'
import styles from '@/styles/crazySaleA.module.scss'
import Image from 'next/image'
import CrazySaleHoursOnly from '@/svgs/crazySaleHoursOnly.svg'
import { Timer } from '@/utils/countDown'
import Link from 'next/link'

interface CrazySaleProps {
  crazyDealData?: {
    productID: string;
    name: string;
    url: string;
    filename: string;
    price: number;
    normalPrice: number;
    crazyDeal: {
      hoursLeft: number;
      minutesLeft: number;
      secondsLeft: number;
    } | undefined
  } | null
}

export default function CrazySale({ crazyDealData }: CrazySaleProps) {
  let timer = new Timer()
  interface TimeRemaining {
    days: number,
    hours: string,
    minutes: string,
    seconds: string,
    isEnd: boolean,
  }

  const [timeDetail, setTimeDetail] = useState<string[]>([])
  const [priceArr, setPrice] = useState(['', ''])

  useEffect(() => {
    if (crazyDealData && crazyDealData.crazyDeal) {
      const { hoursLeft, minutesLeft, secondsLeft } = crazyDealData.crazyDeal
      // 结束时间
      const time = new Date().getTime() + (60 * 60 * hoursLeft + 60 * minutesLeft + secondsLeft) * 1000
      countDown(time)

      // 价格拆分
      setPrice(String(crazyDealData.price).split('.'))
      return () => {
        timer.timerSwitch = false
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crazyDealData])

  function countDown(times: Date | number) {
    timer.countDown(times, (timeRemaining: TimeRemaining) => {
      if (timeRemaining.isEnd) { // 倒计时结束了
        location.reload()
      } else { // 正在倒计时
        let timeDetail: string[] = []
        const { days, hours, minutes, seconds, isEnd } = timeRemaining
        if (isEnd) return
        let hh: string | number = days * 24 + parseInt(hours)
        if (hh <= 99) {
          hh < 10 ? hh = '0' + hh : hh = `${hh}`
          const [h1, h2] = hh.split('')
          timeDetail.push(h1, h2)
        } else {
          timeDetail.push('9', '9')
        }
        const [m1, m2] = minutes.split('')
        const [s1, s2] = seconds.split('')
        timeDetail = [...timeDetail, m1, m2, s1, s2]
        setTimeDetail(timeDetail)
      }
    })
  }

  return (
    <div className={styles['crazySale-box']}>
      <h2 className={styles['title']}>
        <em>Crazy Sale of the Day</em>
      </h2>

      <div className={styles['time']}>
        <Image
          alt='crazysales'
          width={42}
          height={33}
          src={'https://static.crazysales.com.au/images/2022v/img/index/clock-45x36x2.png'} />
        <div className={styles['countDown']}>
          <div className={styles['hh']}>
            <span className={styles['h']}>{timeDetail[0]}</span>
            <span className={styles['h']}>{timeDetail[1]}</span>
          </div>
          <b>:</b>
          <div className={styles['ss']}>
            <span className={styles['s']}>{timeDetail[2]}</span>
            <span className={styles['s']}>{timeDetail[3]}</span>
          </div>
          <b>:</b>
          <div className={styles['mm']}>
            <span className={styles['s']}>{timeDetail[4]}</span>
            <span className={styles['s']}>{timeDetail[5]}</span>
          </div>
        </div>
        <div className={styles['tip']}>
          <CrazySaleHoursOnly></CrazySaleHoursOnly>
        </div>
      </div>

      <div className={styles['commodity']}>
        <Link
          href={`${crazyDealData?.url}`}
          legacyBehavior
        >
          <a rel="noopener noreferrer" style={{ display: 'block' }}>
            {
              crazyDealData?.filename ?
                <Image
                  alt={crazyDealData?.name || ''}
                  width={210}
                  height={210}
                  src={crazyDealData?.filename || ''} />
                : ('')
            }
          </a>
        </Link>
      </div>

      <div className={styles['description']}>
        <Link
          href={`${crazyDealData?.url}`}
          legacyBehavior
        >
          <a rel="noopener noreferrer" style={{ display: 'block' }}>
            <p className={styles['description-name']}>{crazyDealData?.name}</p>
            <div className={styles['price-desc']}>
              <div className={styles['price']}>
                <span>${priceArr[0]}.<i>{priceArr[1]}</i></span>
              </div>
              <div className={styles['marking-price']}>
                <span>${crazyDealData?.normalPrice}</span>
              </div>
            </div>
          </a>
        </Link>
      </div>
    </div>
  )
}
