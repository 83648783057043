
import 'swiper/css'
import 'swiper/css/pagination'
import Image from 'next/image'
import { Pagination, Autoplay } from 'swiper'
import { Swiper } from 'swiper/react'
import React, { ReactNode, useState, forwardRef, useImperativeHandle } from 'react'

import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const imageDomain = publicRuntimeConfig.imageDomain


/**
 * @loop 是否循环播放
 * @pagination 是否展示Dot分页器
 * @slidesPerView 一行显示几个，如需要最后一个元素遮盖可加上小数点
 * @children ReactNode
 */
interface propsVoid {
  autoplay?: boolean;
  delay?: number;
  height?: number;
  hideArrows?: boolean;
  hoverShowArrows?: boolean;
  direction?: string;
  loop?: boolean;
  pagination?: boolean;
  slidesPerView: number;
  slidesPerGroup?: number;
  children: ReactNode;
  slideChange?: Function;
  spaceBetween?: number;
}

export default forwardRef(function SwiperSelf(props: propsVoid, ref?: any) {
  const [swiper, setSwiper]: any = useState(null);

  const slidePrev = () => {
    swiper.slidePrev()
  }

  const slideNext = () => {
    swiper.slideNext()
  }

  // 暴露 Swiper 实例给父组件
  useImperativeHandle(ref, () => ({
    slidePrev,
    slideNext,
  }))

  return (
    <div className={`swiperSelf ${props.hoverShowArrows ? 'hover-show-arrows' : ''}`} ref={ref}>
      <Swiper
        className='swiper-no-swiping'
        modules={[Pagination, Autoplay]}
        height={props.height || null}
        style={{ height: props.height ? props.height + 'px' : 'auto' }}
        spaceBetween={props.spaceBetween || 0}
        loop={props.loop ? true : false}
        direction={props.direction === 'vertical' ? 'vertical' : 'horizontal'}
        pagination={props.pagination ? { clickable: true } : false}
        autoplay={props.autoplay ? { delay: props.delay || 4000 } : false}
        slidesPerView={props.slidesPerView}
        slidesPerGroup={props.slidesPerGroup || parseInt(`${props.slidesPerView}`)}
        onSwiper={(swiper) => setSwiper(swiper)}
        onSlideChange={(event) => {
          props.slideChange && props.slideChange(event.isBeginning, event.isEnd, event.activeIndex);
        }}
      >
        {props.children}
      </Swiper>
      {
        !props.hideArrows &&
        <>
          <div className="swiper-wrapper-prev" onClick={slidePrev}>
            <Image
              src={`${imageDomain}/arrow-left.png`}
              alt={'banner'}
              width={12}
              height={20}></Image>
          </div>
          <div className="swiper-wrapper-next" onClick={slideNext}>
            <Image
              src={`${imageDomain}/arrow-right.png`}
              alt={'banner'}
              width={12}
              height={20}></Image>
          </div>
        </>
      }
    </div>
  )
})