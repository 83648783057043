import { combineReducers } from '@reduxjs/toolkit';
import userInfoReducer from './slices/userInfoSlice';
import cartListSlice from './slices/cartListSlice';

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  cartListSlice: cartListSlice
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
