const accountId = 11526

export const criteoAddtoCart = (items) => {
  if (window.criteo_q) {
    window.criteo_q.push(
      { event: 'setAccount', account: accountId },
      { event: 'setSiteType', type: 'd' },
      {
        event: 'addToCart', item: items
      }
    );
  }
}

export const criteoBeginCheckout = (items) => {
  if (window.criteo_q) {
    window.criteo_q.push(
      { event: 'setAccount', account: accountId },
      { event: 'setSiteType', type: 'd' },
      {
        event: 'beginCheckout', item: items
      }
    );
  }
}