// 文档 https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=zh-cn


/**
 * 加入购物车事件记录
 * @param {*货币} currency 
 * @param {*价值} value 
 * @param {*商品信息数组} productGroup 
 */

export const gaAddToCart = (currency, value, productGroup) => {
  if (window.gtag) {
    var items = []
    productGroup.forEach(function (item) {
      items.push({
        item_id: item.productID,
        item_name: item.productName,
        quantity: item.quantity,
        price: item.price
      })
    })
    window.gtag("event", "add_to_cart", {
      currency: currency || "AUD",
      value: value,
      items: items
    });
  }
}


/**
 * 
 * @param {*货币} currency 
 * @param {*交易的唯一标识符} transaction_id 
 * @param {*与事件相关的货币价值} value 
 * @param {优惠券} coupon 
 * @param {邮费} shipping 
 * @param {*税费} tax 
 * @param {*与事件相关的商品。} items 
 */
export const gaPurchase = ({ currency, transaction_id, value, coupon, shipping, tax, items }) => {
  if (window.gtag) {
    window.gtag("event", "purchase", {
      currency: currency || "AUD",
      transaction_id: transaction_id,
      value: value,
      coupon: coupon || "",
      shipping: shipping || "",
      tax: tax || "",
      items: items
    });
  }
}

export const gaBeginCheckout = ({ currency, value, items }) => {
  if (window.gtag) {
    window.gtag("event", "begin_checkout", {
      currency: currency || "AUD",
      value: value,
      items: items
    })
  }
}