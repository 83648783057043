import styles from '@/styles/myTags.module.scss'
import { ReactNode } from 'react'

interface props {
  isNew?: number | undefined;
  isEmailOnly?: any;
  promotionTypeID: string | number | undefined;
  topPickTag?: ReactNode;
  newTag?: ReactNode;
  onSaleTag?: ReactNode;
  reducedTag?: ReactNode;
  freeShippingTag?: ReactNode;
  clearanceTag?: ReactNode;
  isSingle?: Boolean;
  [key: string]: any
}

// isNew  new

// isEmailOnly  on_sale

// $product->promotion->promotionTypeID ==1 || $product->promotion->promotionTypeID ==99   reduced

// isFreeShipping || $product->promotion->promotionTypeID ==3   free_shipping 

// $product->promotion->promotionTypeID == 4  on_sale

// $product->promotion->promotionTypeID ==  2  on_sale

export default function MyTags(props: props) {
  // 注意：当isSingle为true时，只展示一个
  const { topPickTag, newTag, onSaleTag, reducedTag, freeShippingTag, clearanceTag, promotionTypeID, isSingle, isClearance } = props;

  function addTagsToProduct(productData: props) {

    // 注意优先级
    const tags = [];

    if (promotionTypeID == 1) {
      tags.push("top_pick");
      if (isSingle) return tags
    }

    if (productData.isNew) {
      tags.push("new");
      if (isSingle) return tags
    }

    if (productData.isEmailOnly) {
      tags.push("on_sale-1");
      if (isSingle) return tags
    }

    if (promotionTypeID == 99) {
      tags.push("reduced");
      if (isSingle) return tags
    }

    if (promotionTypeID == 3 || productData.freeShipping) {
      tags.push("free_shipping");
      if (isSingle) return tags
    }

    if (promotionTypeID == 4 || isClearance) {
      tags.push("clearance");
      if (isSingle) return tags
    }

    if (promotionTypeID == 2 && !tags.includes("on_sale-1")) {
      tags.push("on_sale-2");
      if (isSingle) return tags
    }

    return tags;
  }

  const productTags = addTagsToProduct(props)

  return (
    <div className={styles['status-box']}>
      {/* 注意优先级 */}
      {
        productTags.includes("top_pick") &&
        (
          topPickTag
            ?
            topPickTag :
            <div className={styles['status-top_pick']}>Top Pick</div>
        )
      }

      {
        productTags.includes("new") &&
        (
          newTag
            ?
            newTag
            :
            <div className={styles['status-new']}>New</div>
        )
      }

      {
        productTags.includes("on_sale-1") &&
        (
          onSaleTag
            ?
            onSaleTag
            :
            <div className={styles['status-on-sale']}>On Sale</div>
        )
      }

      {
        productTags.includes("reduced") &&
        (
          reducedTag
            ?
            reducedTag
            :
            <div className={styles['status-reduced']}>Reduced</div>
        )
      }

      {
        productTags.includes("free_shipping") &&
        (
          freeShippingTag
            ?
            freeShippingTag
            :
            <div className={styles['status-free-shipping']}>Free Shipping</div>
        )
      }

      {
        productTags.includes('clearance') &&
        (
          clearanceTag ?
            clearanceTag :
            <div className={styles['status-clearance']}>Clearance</div>
        )
      }

      {
        productTags.includes("on_sale-2") &&
        (
          onSaleTag
            ?
            onSaleTag
            :
            <div className={styles['status-on-sale']}>On Sale</div>
        )
      }
    </div>
  )
}