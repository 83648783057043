import { ajax } from '../index'

/**
 * 登录、注册相关接口
 */
export default {
  // 登录
  login(dataObj: any) {
    return ajax.post('api', `/api/v1/login`, dataObj)
  },

  // 注册
  register(dataObj: any) {
    return ajax.post('api', `/api/v1/register`, dataObj)
  },

  // google登录
  googleLogin(dataObj: any) {
    return ajax.post('api', `/api/v1/google/auth`, dataObj)
  },

  // 检查登录支付错误次数，获取google recaptcha的sitekey
  LoginErrorCheck() {
    return ajax.get('api', `/api/v1/login-check`, {})
  },

  // 退出登录
  logout() {
    return ajax.post('api', `/api/v1/logout`, {})
  },

  // 获取用户信息
  me() {
    return ajax.get('api', `/api/v1/customer/me`, {})
  },

  // 通过邮箱检查用户
  checkEmail(email: string) {
    return ajax.get('api', `/api/v1/check-email?email=${email}`, {})
  },

  // 登录注册保存地址
  loginExpress(params:any) {
    return ajax.post('api', `/api/v1/login-express`, params)
  }
}