import viewcartApi from '@/service/modules/viewcartApi';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../store';

interface carListState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  data: {
    available: any[]
    unavailable: any[];
    subtotal: number;
    count: number;
  };
  error: string | null;
}

const initialState: carListState = {
  status: 'idle',
  data: {
    available: [],
    unavailable: [],
    subtotal: 0,
    count: 0
  },
  error: null
};

const dealCartData = (shopCartData: any) => {
  const { list: { available, unavailable }, count, subtotal } = shopCartData.data
  return {
    available: available || [],
    unavailable: unavailable || [],
    subtotal,
    count
  }
}

const userinfoSlice = createSlice({
  name: 'carList',
  initialState,
  reducers: {
    loadingStart: (state) => {
      state.status = 'loading';
    },
    loadingSuccess: (state, action) => {
      state.status = 'succeeded';
      // 保存数据到状态
      state.data = action.payload;
    },
    loadingFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload.message;
    },
    updateCarList: (state, action) => {
      state.data = dealCartData(action.payload);
    }
  }
})

export const {
  loadingStart,
  loadingSuccess,
  loadingFailure,
  updateCarList
} = userinfoSlice.actions;

export const getCarList = () => async (dispatch: AppDispatch) => {
  dispatch(loadingStart());
  try {
    const res = await viewcartApi.getList();
    dispatch(loadingSuccess(dealCartData(res)));
  } catch (error) {
    dispatch(loadingFailure(error));
  }
};



export default userinfoSlice.reducer;