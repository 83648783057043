import myAccountApi from '@/service/modules/myAccountApi';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../store';
import { getCookie, setCookie } from '@/utils/cookie'

interface UserinfoState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  data: {
    customerID: number | string;
    email: string;
    firstName: string;
    lastName: string;
    postCode: string | number;
    suburb: string;
    [key: string]: unknown;
  };
  error: string | null;
}

// 获取本地存储中的用户信息
const getUserInfoFromStorage = (): any => {
  const userInfo = getCookie('userInfo');
  if (userInfo) {
    return JSON.parse(userInfo);
  }
  return null;
};

// 将用户信息保存到本地存储
const saveUserInfoToStorage = (userInfo: UserinfoState) => {
  // 设置半小时过期
  const expires = new Date(new Date().getTime() + (0.5 * 60 * 60 * 1000))
  setCookie("userInfo", JSON.stringify(userInfo), { expires })
};

const initialState: UserinfoState = {
  status: 'idle',
  data: getUserInfoFromStorage() || {
    customerID: "",
    email: '',
    firstName: "",
    lastName: "",
    postCode: 2000,
    suburb: ""
  },
  error: null
};

const userinfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    loadingStart: (state) => {
      state.status = 'loading';
    },
    loadingSuccess: (state, action) => {
      state.status = 'succeeded';
      // 保存数据到状态
      if (!!action.payload.data.customerID) {
        state.data = action.payload.data;
        saveUserInfoToStorage(action.payload.data)
      }
    },
    loadingFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload.message;
    },
    updateUserInfo: (state, action) => {
      state.data = action.payload;
      saveUserInfoToStorage(action.payload)
    }
  }
})

export const {
  loadingStart,
  loadingSuccess,
  loadingFailure,
  updateUserInfo
} = userinfoSlice.actions;

export const getUser = () => async (dispatch: AppDispatch) => {
  dispatch(loadingStart());
  try {
    const res = await myAccountApi.getCurrentCustomerInfo()
    dispatch(loadingSuccess(res));
  } catch (error) {
    dispatch(loadingFailure(error));
  }
};



export default userinfoSlice.reducer;