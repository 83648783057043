import styles from '@/styles/menu.module.scss'
import Image from 'next/image'
import Link from 'next/link'
import { useState, useEffect } from "react"
import { Skeleton } from 'antd';
import { CaretDownOutlined, MenuOutlined } from '@ant-design/icons'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import MenuSubCategory from './MenuSubCategory'

const { publicRuntimeConfig } = getConfig()
const apiDomain = publicRuntimeConfig.apiDomain
const imageDomain = publicRuntimeConfig.imageDomain

interface props {
  wideScreen?: boolean;
  useChristmasTheme?: boolean;
}

export default function Menu({ wideScreen, useChristmasTheme }: props) {
  function handleClickTopMenu(url: string, e: any) {
    // 阻止a标签默认行为
    e.preventDefault()

    window.location.href = `${url}`
  }

  // 菜单列表组件
  const menuList = [
    { name: 'Home', linkTitle: 'Home', link: `${apiDomain}/` },
    { name: 'Clearance', linkTitle: 'clearance', link: `${apiDomain}/clearance` },
    { name: 'Free Shipping', linkTitle: 'free shipping', link: `${apiDomain}/free-shipping` },
    { name: 'New Arrivals', linkTitle: 'new arrivals', link: `${apiDomain}/new-arrivals` },
    { name: 'Hot Deals', linkTitle: 'hot deals', link: `${apiDomain}/hottest-deals` },
    { name: 'Top Review', linkTitle: 'top reviewed', link: `${apiDomain}/top-reviewed-1` }
  ]
  const menuItems = menuList.map(menu =>
    <Link 
      key={menu.name}
      href={menu.link}
      passHref
      legacyBehavior
    >
      <a 
        title={menu.name}
        rel="noopener noreferrer"
        onClick={(e: any) => handleClickTopMenu(`${menu.link}`, e)} 
      >{menu.name}</a>
    </Link>
  )

  // 判断当前页面是否为 首页，首页需要展开分类
  const router = useRouter()
  let showCate = false
  if (router.pathname === '/') {
    showCate = true
  }

  return (
    <div className={styles['menu-box']} id="menu">
      <div className={`${styles['menu-content']} ${ wideScreen ? styles['menu-content-wide'] : ''} `}>
        <div className={`${styles['all-categories']} ${ showCate ? styles['show'] : ''} `}>
          <div className={styles['all-categories-title']}>
            <MenuOutlined className={styles['icon-menu']} />
            All Categories
            <CaretDownOutlined className={styles['icon-down']} />
          </div>

          <MenuSubCategory></MenuSubCategory>
        </div>

        <div className={styles['menu-list']}>
          {menuItems}
        </div>

        {
          useChristmasTheme &&
          <Image
            className={styles['nav-doll']}
            alt="doll"
            width={109}
            height={152}
            src={`${imageDomain}/christmas-theme/nav-doll.png`} />
          }
      </div>
    </div>
  )
}
