import styles from '@/styles/goodsComponent.module.scss'
import { Rate, Button, message } from 'antd'
import Image from 'next/image'
import { getToken } from '@/utils/auth'
import { localStorageService } from "@/utils/storageService"
import FreeShipping from '@/svgs/freeShipping.svg'
import Discount from '@/svgs/discount.svg'
import { useState } from 'react'
import getConfig from 'next/config';
import Link from 'next/link'
import MyTags from "@/components/MyTags"
import useCartActions from '@/utils/addTocartAction'

const { publicRuntimeConfig } = getConfig();
const apiDomain = publicRuntimeConfig.apiDomain;

/**
 * @type 1.CrazyDeal 2.Recommend
 */
interface GoodsDetail {
  type: string,
  position?: number,
  location?: string;

  productID: number;
  name: string;
  url: string;
  imgUrl?: string;

  thumbnail?: string;
  smallThumbnail?: string;
  newThumbnail?: string;
  filename?: string;

  price: number;
  normalPrice: number;

  isGroup: number;
  inStock: number;
  preSales: number;

  freeShipping: number;
  onSale: number;
  reduced: number;
  isNew: number;
  isEmailOnly: any,
  promotionTypeID: number;

  promotion: {
    productID: number;
    promotionType: number;
    promotionPrice: number;
    normalPrice: number;
    endDate: string;
    pointRate: string;
    promotionRate: number;
  };
  review: {
    avgRank: number;
    total: number;
  };

  tagTitle?: string;
  tagBgColor?: string;
  tagFontColor?: string;

  [key: string]: any;
}

export default function GoodsComponent(props: GoodsDetail) {
  const { addToCart } = useCartActions();

  const [showAdded, setShowAdded] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  const addToCartCallBack = (response: any) => {
    if (response != "error") {
      setShowAdded(true)
      setBtnLoading(false)
    } else {
      setBtnLoading(false)
    }
  }

  const preOrder = async (event: any) => {
    event.stopPropagation()
    setBtnLoading(true)
    const isLogin = getToken() == '1'
    const pid = String(props.productID)
    if (!isLogin) {
      location.href = '/login?redirect=' + encodeURIComponent('/checkout?p=' + pid + '&t=' + 1);
      return false;
    }
    location.href = '/checkout?p=' + pid + '&t=1';
  }

  let price = `${Number(props.normalPrice).toFixed(2)}`.split('.')
  if (props.price && props.price != props.normalPrice) {
    price = `${Number(props.price).toFixed(2)}`.split('.')
  }
  const styleOfCrazyDeal = {
    priceStyleB: {
      fontSize: '26px'
    },
    priceStyleC: {
      fontSize: '22px'
    },
    priceStyleI: {
      fontSize: '16px'
    }
  }

  const styleOfRecommend = {
    priceStyleB: {
      fontSize: '20px'
    },
    priceStyleC: {
      fontSize: '16px'
    },
    priceStyleI: {
      fontSize: '14px'
    }
  }
  const { type } = props
  const styleVersion = type === 'Recommend' ? styleOfRecommend : styleOfCrazyDeal
  return (
    <div className={`${styles['goods-box']} ${type === 'Recommend' && styles['Recommend']}`}>
      <div className={styles['imgthumb']}>
        <Link
          href={props.url}
          legacyBehavior
        >
          <a className={styles['goodsLink']} rel="noopener noreferrer">
            <Image
              src={props.imgUrl || ''}
              alt={props.productName}
              width={200}
              height={200}
            />
          </a>
        </Link>
        {
          !!(props.inStock && props.isGroup == 0) &&
          <>
            {
              props.preSales == 1
                ?
                <div className={styles['act_user_sel']}>
                  <Link
                    href={props.url}
                    legacyBehavior
                  >
                    <a rel="noopener noreferrer" style={{ display: 'block', width: '100%', height: '100%' }}>
                    </a>
                  </Link>
                  <div className={styles['sel_add']}>
                    <Button className={styles['pre_order_btn']} onClick={preOrder} loading={btnLoading} >PRE-ORDER</Button>
                  </div>
                </div>
                :
                <div className={styles['act_user_sel']} style={showAdded ? { display: 'block' } : {}}>
                  <Link
                    href={props.url}
                    legacyBehavior
                  >
                    <a rel="noopener noreferrer" style={{ display: 'block', width: '100%', height: '100%' }}>
                    </a>
                  </Link>
                  <div className={styles['sel_add']} style={showAdded ? { display: 'none' } : {}}>
                    <Button className={styles['add_addtocart_btn']} onClick={(event) => { setBtnLoading(true); addToCart(event, { productID: props.productID, qty: 1, location: props.location, position: props.position }, addToCartCallBack) }} loading={btnLoading}>Add to Cart</Button>
                  </div>
                  <div className={styles['added']} style={showAdded ? { display: 'block' } : {}}>
                    <p className={styles['addtip']}>
                      <i className={`iconfont csicon-shopping-cart ${styles['icon-shopping-cart']}`} />
                      <span>Item added to your cart!</span>
                    </p>
                    <Button className={styles['cart_btn']} onClick={(event) => { event.stopPropagation(); window.location.href = "/viewcart" }}>View Cart</Button>
                    <Button className={styles['checkout_btn']} onClick={(event) => { event.stopPropagation(); window.location.href = "/checkout" }}>Checkout</Button>
                  </div>

                </div>
            }

          </>
        }
      </div>
      <div className={styles['goods-info']}>
        <Link
          href={props.url}
          legacyBehavior
        >
          <a className={styles['goodsLink']} rel="noopener noreferrer">
            <h3 className={styles['goods-name']} dangerouslySetInnerHTML={{ __html: props.productName }} />
          </a>
        </Link>
        <div className={styles['goods-price']}>
          <div className={styles['price']}>
            <b style={styleVersion.priceStyleB}>${price[0]}<i style={styleVersion.priceStyleC}>.{price.length === 2 ? price[1] : '00'}</i></b>
          </div>
          {
            (props.normalPrice && props.normalPrice !== props.price)
            &&
            <div className={styles['crossedPrice']}>
              <b style={styleVersion.priceStyleI}>$ {props.normalPrice}</b>
            </div>
          }
        </div>

        {
          props.inStock
            ?
            // 有库存
            <>
              {
                <div className={`${styles['goods-start']} ${props.review.total - 0 > 0 ? '' : styles['goods-start-hidden']}`}>
                  <Rate style={{ fontSize: '16px', color: '#ffc33d' }} allowHalf disabled value={props.review.avgRank} />
                  <span className={styles['commitNum']}>({props.review.total})</span>
                </div>
              }
              {/* Recommend模块专属标签 */}
              {
                props.type === 'Recommend' &&
                <MyTags {...props} />
              }
              {/* CrazyDeal模块专属标签 */}
              {
                !!(props.type === 'CrazyDeal' && !!(props.freeShipping == 1 || props.promotionTypeID > 0))
                &&
                <div className={styles['coupon-deals']}>
                  <div className={styles['desc']}>{props.freeShipping == 1 ? 'Free Delivery' : 'Exclusive Discount'}</div>
                  <div className={styles['icon']}>
                    {
                      props.freeShipping == 1
                        ? <FreeShipping style={{ fill: '#ffffff' }}></FreeShipping>
                        : <Discount style={{ fill: '#ffffff' }}></Discount>
                    }
                  </div>
                </div>
              }
            </>
            :
            // 无库存时候显示，所有商品
            <div className={styles['notify-box']}>
              <Button className={styles['btn']} href={props.url} type="primary">NOTIFY ME</Button>
            </div>
        }
      </div>
      {
        props.type === 'CrazyDeal' && props.tagTitle
        &&
        <div className={styles['tag']} style={{ backgroundColor: props.tagBgColor, color: props.tagFontColor }}>
          {props.tagTitle}
        </div>
      }
    </div>
  )
}