import Ajax from './core/Ajax'
import { removeCookie } from '@/utils/cookie'
import getConfig from 'next/config'
import { localStorageService } from "@/utils/storageService"

const { publicRuntimeConfig } = getConfig()
const apiDomain = publicRuntimeConfig.apiDomain

//创建网络实体对象
const createAjaxInstance = (options?: any) => {
  return new Ajax({ timeout: 45000, withCredentials: true, ...options })
}

//业务网络对象
export const ajax = createAjaxInstance()

//刷新token使用(不走拦截器,避免循环问题)
export const fetch = createAjaxInstance()

//请求拦截器
ajax.axiosInstance.interceptors.request.use(
  async (config: any) => {
    // 设置请求header
    const customHeader = {
      'Cache-Control': 'no-store',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'X-Service-Group': 'BG',
      'X-Certain-Group': 'BG',
      'Accept': '*/*',
      'Authorization': localStorageService.getItem('userToken')
    }
    config.headers = Object.assign({}, config.headers, customHeader)    
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

//响应拦截器
ajax.axiosInstance.interceptors.response.use(
  (response: any) => {
    try {
      if (
        (response.status == 200 || response.status == 201)
      ) {
        if (response.data.code <= 100000) {
          // 如果当前的token和返回的不一样（必须判断是登录状态下），就把localStorage的userToken 重新赋值
          if (
            typeof window != 'undefined' 
            && response.headers['authorization']
            && window.localStorage.getItem('userEmail')
            && `Bearer ${response.headers['authorization']}` != window.localStorage.getItem('userToken')
          ) {
            window.localStorage.setItem('userToken', `Bearer ${response.headers['authorization']}`)
          }


          // 赋值登录和注册接口的token
          if (
            typeof window != 'undefined' 
            && response.headers['authorization']
            && (
              response.config.url.indexOf('/api/v1/login') > -1
              || response.config.url.indexOf('/api/v1/register') > -1
              || response.config.url.indexOf('/api/v1/google/auth') > -1
              || response.config.url.indexOf('/api/v1/payment/after-pay/express/capture') > -1
              || response.config.url.indexOf('/api/v1/payment/paypal-express/capture') > -1
            )
          ) {
            window.localStorage.setItem('userToken', `Bearer ${response.headers['authorization']}`)
          }
          return Promise.resolve(response.data)
        } else {
          return Promise.reject(response.data)
        }
      }
      return Promise.reject(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  (error: any) => {
    if (error.response.status == 401) {
      localStorage.removeItem('tokenExpired')
      localStorage.removeItem('userToken')
      localStorage.removeItem('userEmail')
      removeCookie('PHPSESSID')
      removeCookie('userCustomerId')

      const currentPath = window.location.pathname + window.location.search;
      const encodedRedirect = encodeURIComponent(currentPath);
      
      window.location.href = `${apiDomain}/login?redirect=${encodedRedirect}`;

      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.response.data)
  }
)

export default {
  ajax,
  fetch
}
