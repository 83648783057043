import { gaAddToCart } from "@/utils/gaEvent";
import { criteoAddtoCart } from "@/utils/criteoEvent";
import { message } from "antd";
import viewcartApi from '@/service/modules/viewcartApi';
import { updateCarList } from "@/store/slices/cartListSlice";
import { AppDispatch } from "@/store/store";
import { useDispatch } from "react-redux";

interface ParamsInterface {
  productID: string | number,
  qty: string | number,
  location?: string,
  position?: number,
  pages?: number
}

interface BatchParams {
  productList: Array<{ pid: string | number, qty: string | number }>,
  location?: string,
  position?: number,
  pages?: number
}

const useCartActions = () => {
  const dispatch: AppDispatch = useDispatch();

  const addToCart = async (event: any, parameter: ParamsInterface, callback?: Function) => {
    event.stopPropagation();
    const { productID, qty } = parameter;
    const params = { items: [{ pid: parseInt(`${productID}`), qty }] };
    await commonAddCart(params, parameter, dispatch, callback);
  }

  const batchAddToCart = async (event: any, parameter: BatchParams, callback?: Function) => {
    event.stopPropagation();
    const { productList } = parameter;
    const params = { items: productList };
    await commonAddCart(params, parameter, dispatch, callback);
  }

  return { addToCart, batchAddToCart };
}

const commonAddCart = async (params: any, queryData: any, dispatch: AppDispatch, callback?: Function,) => {
  try {
    const query = buildQuery(params.items, queryData);

    await viewcartApi.addToCart(params, query);

    const shopCartData = await viewcartApi.getList();
    const { list: { available } } = shopCartData.data
    // 更新状态购物车数据
    dispatch(updateCarList(shopCartData));
    callback && callback(shopCartData);

    if (params.items.length === 1) {
      handleAnalytics(params.items[0].pid, available);
    }
  } catch (error: any) {
    message.error(error.message);
    callback && callback("error");
  }
}

const buildQuery = (items: any[], queryData: any) => {
  if (items.length === 1) {
    const { location, position, pages } = queryData;
    if (location && (position === 0 || position)) {
      return `?pid=${items[0].pid}&location=${location}&position=${position || 0}&page=${pages || 1}`;
    }
  }
  return "";
}

const handleAnalytics = (productID: string | number, available: any[]) => {
  const goodsItem = available.find((v: any) => v.productID == productID);
  console.log("goodsItem", goodsItem);
  gaAddToCart("AUD", goodsItem.price, [{ ...goodsItem, quantity: 1 }]);

  if (window && (window as any)._paq) {
    (window as any)._paq.push(['setCustomDimension', 28, JSON.stringify({ productID, quantity: 1 })]);
    (window as any)._paq.push(['trackPageView']);
  }

  const ScarabQueue: any = (window as any).ScarabQueue || [];
  if (ScarabQueue) {
    const userEmail = localStorage.getItem('userEmail');
    if (userEmail) {
      ScarabQueue.push(['setEmail', userEmail]);
    }

    const scarabQueueCartData = available.map((item: any) => ({
      item: item.productID,
      price: Number(item.price),
      quantity: Number(item.quantity),
    }));

    ScarabQueue.push(['cart', scarabQueueCartData]);
    ScarabQueue.push(['go']);
  }

  const criteoParams: any = {
    id: goodsItem.productID,
    price: goodsItem.price,
    quantity: 1,
  }
  criteoAddtoCart([criteoParams])

}

export default useCartActions;
