import { ajax } from '../index'

/**
 * 购物车 相关接口
 */
export default {
  // 列表
  getList() {
    return ajax.get('api', `/api/v1/cart/list`, {})
  },

  // 新增购物车
  addToCart(params: any, query?: any) {
    return ajax.post('api', `/api/v1/cart/add${query}`, params)
  },

  // 删除购物车
  deleteCart(params: any) {
    return ajax.delete('api', `/api/v1/cart/delete`, { data: params })
  },

  // 对比购物车
  compareCart(params: any) {
    return ajax.post('api', `/api/v1/cart/compare`, params)
  },

  // 更新购物车
  updateCart(params: any) {
    return ajax.put('api', `/api/v1/cart/update`, params)
  },

  // 计算已选择的邮费
  calculateSelectPost(params: any) {
    return ajax.get('api', `/api/v1/shipping/calc-selected`, params)
  },

  // 计算未选中的邮费信息
  calcSingle(params: any) {
    return ajax.post('api', `/api/v1/shipping/calc-single`, params)
  },

  // 全选反选
  selectAll(params: any) {
    return ajax.put('api', `/api/v1/cart/select-all`, params)
  },

  // 购物车相关产品列表
  related(params: any) {
    return ajax.post('api', `/api/v1/mooncake/related`, params)
  },

  // 优惠接口
  applyDiscount(params: any) {
    return ajax.post('api', `/api/v1/cart/apply-discount`, params)
  }
}